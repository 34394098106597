import { rem } from 'polished';
import styled, { css } from 'styled-components';

export const StyledList = styled.ul`
  margin: 0;
  padding: 0;

  margin-bottom: ${rem('32px')};
`;

export const StyledListIcon = styled.span<{ width: string; user: string }>`
  display: inline-flex;
  width: ${({ width }) => rem(width)};
  margin-bottom: ${rem('16px')};

  svg path {
    fill: ${({ theme, user }) => theme.colors[user].main};
  }
`;

export const StyledListItem = styled.li`
  list-style: none;
`;

export const StyledListTitle = styled.h3`
  margin: 0 0 ${rem('8px')} 0;
  color: ${({ theme }) => theme.colors.cross.black};
  font-size: ${rem('20px')};
  font-weight: 600;
`;

export const StyledListText = styled.p<{ user: string }>`
  color: ${({ theme }) => theme.colors.cross.grayson};
  margin: 0;
  font-size: ${rem('16px')};
  font-weight: 400;
  line-height: 1.4;

  a {
    color: ${({ theme, user }) => theme.colors[user].main};
    text-decoration: none;
  }
`;

export const StyledListProgressBarWrapper = styled.div`
  width: 100%;
  margin-top: ${rem('32px')};
  position: relative;
`;

export const StyledListProgressBarNumbersWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
`;

export const StyledListProgressBarNumber = styled.button<{
  isActive: boolean;
  user: string;
}>`
  border: none;
  outline: none;
  cursor: pointer;
  background: none;

  width: ${rem('32px')};
  height: ${rem('32px')};
  display: grid;
  place-items: center;

  border: ${rem('2px')} solid
    ${({ theme, isActive, user }) =>
      isActive ? theme.colors[user].main : theme.colors.cross.lightGray};
  border-radius: 50%;
  color: ${({ theme, isActive }) =>
    isActive ? theme.colors.main : theme.colors.cross.lightGray};
  background-color: ${({ theme, isActive, user }) =>
    isActive ? theme.colors[user].main : theme.colors.main};

  font-weight: 700;
  font-size: ${rem('16px')};

  transform: scale(${({ isActive }) => (isActive ? 1 : 0.8)});

  transition: all 0.3s linear;

  ${({ isActive, theme, user }) =>
    !isActive &&
    css`
      &:hover {
        border-color: ${theme.colors[user].main};
        color: ${theme.colors[user].main};
      }
    `}
`;

export const StyledListProgressBarLine = styled.div`
  border: ${rem('1px')} solid ${({ theme }) => theme.colors.cross.lightGray};
  position: absolute;
  width: 95%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

import { motion } from 'framer-motion';
import React, { Fragment, useEffect, useState } from 'react';

import {
  StyledList,
  StyledListIcon,
  StyledListItem,
  StyledListProgressBarLine,
  StyledListProgressBarNumber,
  StyledListProgressBarNumbersWrapper,
  StyledListProgressBarWrapper,
  StyledListText,
  StyledListTitle,
} from './SequenceList.styles';
import { ISequenceListProps } from './SequenceList.types';

const SequenceList = ({ content, user }: ISequenceListProps): JSX.Element => {
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);

  useEffect(() => {
    const sliderInterval = setInterval(() => {
      setActiveSlideIndex(prevActiveSlideIndex =>
        prevActiveSlideIndex + 1 === content.length
          ? 0
          : prevActiveSlideIndex + 1,
      );
    }, 4000);

    return () => {
      clearInterval(sliderInterval);
    };
  }, [activeSlideIndex]);

  const handleOnProgressBarNumberClick = (newActiveSlideIndex: number) => {
    setActiveSlideIndex(newActiveSlideIndex);
  };

  return (
    <>
      <StyledList>
        <>
          {content.map((item, index) => (
            <Fragment key={index}>
              {activeSlideIndex === index && (
                <motion.div
                  initial={{ scale: 0, opacity: 0 }}
                  animate={{ scale: 1, opacity: 1 }}
                >
                  <StyledListItem key={item.title}>
                    {item.icon && item.iconWidth && (
                      <StyledListIcon width={item.iconWidth} user={user}>
                        {item.icon}
                      </StyledListIcon>
                    )}
                    <StyledListTitle>{item.title}</StyledListTitle>
                    <StyledListText user={user}>{item.text}</StyledListText>
                  </StyledListItem>
                </motion.div>
              )}
            </Fragment>
          ))}
          <StyledListProgressBarWrapper>
            <StyledListProgressBarLine />
            <StyledListProgressBarNumbersWrapper>
              {content.map((_, index) => (
                <StyledListProgressBarNumber
                  isActive={activeSlideIndex === index}
                  key={index}
                  user={user}
                  onClick={handleOnProgressBarNumberClick.bind(null, index)}
                >
                  {index + 1}
                </StyledListProgressBarNumber>
              ))}
            </StyledListProgressBarNumbersWrapper>
          </StyledListProgressBarWrapper>
        </>
      </StyledList>
      <noscript>
        <ul>
          {content.map((item, index) => (
            <li key={index}>
              <h3>{item.title}</h3>
              <p>{item.text}</p>
            </li>
          ))}
        </ul>
      </noscript>
    </>
  );
};

export default SequenceList;
